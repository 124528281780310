import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri, CommAxis, SpinSeri } from "../../../../script/components/chart/charts-item";

export function ViewSubsChar(props) {
    const { loading , data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${arg.value}`,
        };
    };
    return (
        <DashCard
            title={"사용 현황"}
            addClass={'view-char'}
            loading={loading}
        >
            <CommChart
                height={250}
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {BarsSeri({ valueField: "scre_name", caption: "접속횟수", color: "#FFA726", args: "grou_coun" })}
            </CommChart>
        </DashCard>
    )
}