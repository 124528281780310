import React from "react";
import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";

export function DashProdItemCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"제조리드타임 산출근거"}
            addClass={'subs'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={"kpis_gubu"}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {CommCol({ dataField: "item_name", caption: "제 품 명", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {CommCol({ dataField: "work_dats", caption: "작업시작일자", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {CommCol({ dataField: "work_date", caption: "작업종료일자", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "date_diff", caption: "작업일수", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {CommCol({ dataField: "ship_date", caption: "납품일자", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "date_ship", caption: "제품소요일수", allowFiltering: false, allowSorting: false, allowResizing: false })}
            </ScrollGrid>
        </DashCard>
    )
}