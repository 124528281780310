import { useCallback, useEffect, useState } from 'react';
import DataGrid, { LoadPanel as GridPanel, Pager, Paging, Scrolling, Sorting, Selection, HeaderFilter, Editing, Toolbar, Item, SearchPanel, ColumnChooser, Export, GroupPanel, Grouping } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import { GridToolbar } from '../../toolbar/toolbar';
import { fwUtil } from '../../../util';
import { gridUtil } from './grid-body-util';
import './grid-body.scss';

const defaultExportFormat = ['xlsx'];

const PagingGrid = (props) => {
    const {
        title, role, width, height,
        gridSize, mainKey, gridRef, gridDataSource,
        onRowClick, onRowDblClick,
        dateValue, dateValueChange, duraYesn, duraYesnChange,
        edit, toolbar, ins, upd, del, exp, search,
        selection, children,
        dispose, grouping,
        hideColumnChooser
    } = props;

    const searchVisible = fwUtil.conv.tern(true, search);
    const exportFormat = exp && fwUtil.conv.tern(exp.formats, defaultExportFormat);
    const [pagingGridHeight, setPagingGridHeight] = useState(0);
    const [pagingGridPageSize, setPagingGridPageSize] = useState(0);
    const pagingGridHeightUpdate = useCallback((e) => setPagingGridHeight(e), []);
    const pagingGridPageSizeUpdate = useCallback((e) => setPagingGridPageSize(e), []);

    useEffect(() => {
        const { girdHeight, gridPageSize } = gridUtil.calc.height.page(height, gridSize);
        pagingGridHeightUpdate(girdHeight);
        pagingGridPageSizeUpdate(gridPageSize);
    }, [pagingGridHeightUpdate, pagingGridPageSizeUpdate, height, gridSize]);

    if (dispose || pagingGridPageSize === 0) {
        return null;
    }

    return (
        <div className='fw-grid-content'>
            <DataGrid
                {...gridUtil.opts.comm(props, 'page')}
                ref={gridRef}
                width={width}
                height={pagingGridHeight}
                keyExpr={mainKey}
                dataSource={gridDataSource}
                onRowClick={onRowClick}
                onRowDblClick={onRowDblClick}
                focusedRowEnabled={selection === 'multiple' ? true : false}
                columnAutoWidth
                rowAlternationEnabled
            >
                <GridPanel enabled />
                <HeaderFilter visible />
                <Sorting mode='multiple' />
                <Scrolling columnRenderingMode='standard' />
                <Paging enabled pageSize={pagingGridPageSize - 1} onPageIndexChange={() => gridUtil.handlePageChanged(gridRef)} />
                <Pager showNavigationButtons showPageSizeSelector={false} />
                <Selection {...getSelectionPagingProps(selection)} />
                {grouping && <GroupPanel visible />}
                {grouping && <Grouping autoExpandAll />}
                {toolbar && !hideColumnChooser && <ColumnChooser enabled />}
                {toolbar && <SearchPanel visible={searchVisible} placeholder='검색' />}
                {exp && <Export enabled allowExportSelectedData={selection === "multiple"} formats={exportFormat} />}
                {edit && <Editing mode="cell" allowUpdating />}
                {toolbar && GridToolbar({
                    title: `${title} ${role}`,
                    dateValue: dateValue,
                    dateValueChange: dateValueChange,
                    duraYesn: duraYesn,
                    duraYesnChange: duraYesnChange,
                    ins: ins, upd: upd, del: del, exp: exp,
                    defaultItems: toolbar.default,
                    toolbar: toolbar,
                })}
                {children}
            </DataGrid>
            <div className={'fw-fake-pager'} />
        </div>
    );
};

const ScrollGrid = (props) => {
    const {
        title, role, width, height,
        mainKey, gridRef, gridDataSource,
        onRowClick, onRowDblClick,
        dateValue, dateValueChange, duraYesn, duraYesnChange,
        edit, toolbar, ins, upd, del, exp, search,
        selection, children,
        dispose, alter,
        hideColumnChooser
    } = props;

    const rowAlter = fwUtil.conv.tern(true, alter);
    const searchVisible = fwUtil.conv.tern(true, search);
    const exportFormat = exp && fwUtil.conv.tern(exp.formats, defaultExportFormat);

    if (dispose) {
        return null;
    }

    return (
            <DataGrid
                {...gridUtil.opts.comm(props, 'scroll')}
                width={width}
                height={height}
                keyExpr={mainKey}
                ref={gridRef}
                dataSource={gridDataSource}
                onRowClick={onRowClick}
                onRowDblClick={onRowDblClick}
                focusedRowEnabled={selection ? true : false}
                rowAlternationEnabled={rowAlter}
                columnAutoWidth
                renderAsync
            >
                <GridPanel enabled />
                <HeaderFilter visible />
                <Sorting mode='multiple' />
                <Scrolling columnRenderingMode='standard' mode='virtual' renderAsync={false} preloadEnabled />
                <Paging enabled={false} />
                <Selection {...getSelectionScrollProps(selection)} />
                {toolbar && !hideColumnChooser && <ColumnChooser enabled />}
                {toolbar && <SearchPanel visible={searchVisible} placeholder='검색' />}
                {exp && <Export enabled allowExportSelectedData={selection === "multiple"} formats={exportFormat} />}
                {edit && <Editing mode="cell" allowUpdating />}
                {toolbar && GridToolbar({
                    title: `${title} ${role}`,
                    dateValue: dateValue,
                    dateValueChange: dateValueChange,
                    duraYesn: duraYesn,
                    duraYesnChange: duraYesnChange,
                    ins: ins,
                    upd: upd,
                    del: del,
                    exp: exp,
                    defaultItems: toolbar.default,
                    toolbarBeforeItems: toolbar.before,
                    toolbarAfterItems: toolbar.after,
                    toolbar: toolbar,
                    search: search,                    
                })}
                {children}
            </DataGrid>
    )
};

const AddingGrid = (props) => {
    const {
        title, width, height,
        gridRef, gridDataSource,
        allowEdit, allowInsert, allowUpdate, allowDelete, additionTool,
        children
    } = props;
    const gridHeight = fwUtil.conv.tern(266, height);

    return (
        <DataGrid
            ref={gridRef}
            dataSource={gridDataSource}
            width={width}
            height={gridHeight}
            showBorders
            showColumnLines
            columnAutoWidth
            allowColumnResizing
            allowColumnReordering
        >
            <Paging enabled={false} />
            {allowEdit &&
                <Editing
                    mode="cell"
                    startEditAction={'click'}
                    allowAdding={allowInsert}
                    allowUpdating={allowUpdate}
                    allowDeleting={allowDelete}
                    confirmDelete={false}
                    selectTextOnEditStart
                />
            }
            <Scrolling mode='standard' columnRenderingMode="standard" />
            <Toolbar>
                <Item location='before'>
                    <span className='fw-additional-label'>{title}</span>
                </Item>
                <Item location='after'>
                    {allowInsert &&
                        <Button
                            icon={'add'}
                            onClick={() => gridDataSource.store().insert({}).then(() => gridDataSource.reload())}
                        />
                    }
                    {additionTool && additionTool}
                </Item>
            </Toolbar>
            {children}
        </DataGrid>
    );
}

const AddingCGrid = (props) => {
    const {
        title, width, height, mainKey, selection,
        gridRef, gridDataSource, onRowClick, 
        allowEdit, allowInsert, allowUpdate, allowDelete, additionTool,
        children
    } = props;
    const gridHeight = fwUtil.conv.tern(266, height);

    return (
        <DataGrid
            keyExpr={mainKey}
            ref={gridRef}
            dataSource={gridDataSource}
            width={width}
            height={gridHeight}
            showBorders
            showColumnLines
            columnAutoWidth
            allowColumnResizing
            allowColumnReordering
            onRowClick={onRowClick}
            focusedRowEnabled={selection ? true : false}
        >
            <Paging enabled={false} />
            {allowEdit &&
                <Editing
                    mode="cell"
                    startEditAction={'click'}
                    allowAdding={allowInsert}
                    allowUpdating={allowUpdate}
                    allowDeleting={allowDelete}
                    confirmDelete={false}
                    selectTextOnEditStart
                />
            }
            <Scrolling mode='standard' columnRenderingMode="standard" />
            <Toolbar>
                <Item location='before'>
                    <span className='fw-additional-label'>{title}</span>
                </Item>
                <Item location='after'>
                    {allowInsert &&
                        <Button
                            icon={'add'}
                            onClick={() => gridDataSource.store().insert({}).then(() => gridDataSource.reload())}
                        />
                    }
                    {additionTool && additionTool}
                </Item>
            </Toolbar>
            {children}
        </DataGrid>
    );
}

export {
    PagingGrid,
    ScrollGrid,
    AddingGrid,
    AddingCGrid,
}

const getSelectionPagingProps = (selection) => {
    switch (selection) {
        case 'single':
            return { showCheckBoxesMode: 'none', mode: 'single' };
        case 'multiple':
            return { selectAllMode: 'page', showCheckBoxesMode: 'always', mode: 'multiple' };
        default:
            return { mode: 'none' };
    }
};

const getSelectionScrollProps = (selection) => {
    switch (selection) {
        case 'single':
            return { showCheckBoxesMode: 'none', mode: 'single' };
        case 'multiple':
            return { selectAllMode: 'allPages', showCheckBoxesMode: 'always', mode: 'multiple' };
        default:
            return { mode: 'none' };
    }
};