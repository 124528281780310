import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupAddr, PopupForm } from '../../../../script/components/popup/popup';
import { FormAuto, FormDate, FormGrid, FormMemo, FormRegx, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { bankList } from './infoStat-util'
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    clie_code: '', 
    clie_name: '', 
    clie_enam: '', 
    pres_name: '',
    clie_sano: '', 
    upta_name: '', 
    jong_moks: '', 
    clie_gubu: '', 
    taxb_mail: '',
    tele_numb: '', 
    faxs_numb: '', 
    bank_name: '', 
    hold_name: '', 
    acco_numb: '',
    zips_code: '', 
    addr_knam: '', 
    addr_enam: '', 
    damd_name: '', 
    dept_name: '',
    damd_teln: '', 
    damd_faxn: '', 
    mail_idno: '', 
    clie_memo: '', 
    work_code: '',
    posi_name: '', 
    damd_cell: '', 
    home_page: '', 
    clie_sert: '', 
    eval_date: null, 
    eval_scor: '', 
    eval_term: '', 
    eval_memo: '', 
    taxb_name: '', 
    taxb_teln: '', 
    clie_loca: '국내', 
};

const setClie = set.mes.com.clie;

export function ClieInfoStatInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 구분 리스트
    const [gubuList, setGubuList] = useState([]);
    const gubuChange = useCallback((e) => setGubuList(e), []);
    // 주소 팝업
    const [addrPopupVisible, setAddrPopupVisible] = useState(false);
    const addrPopupHide = useCallback(() => setAddrPopupVisible(false), []);
    const addrPopupConfirm = useCallback((postData) => {
        const updateedData = {
            ...data,
            zips_code: postData.zonecode,
            addr_knam: postData.address,
            addr_enam: postData.addressEnglish,
        };
        addrPopupHide();
        dataUpdate(updateedData);
    }, [data, addrPopupHide, dataUpdate]);

    const fetchSet = useCallback(async () => {
        try {
            const [gubuResult] = await Promise.all([setClie.gubu()]);
            gubuChange(gubuResult);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.s1Erro()
            hiding();
        }
    }, [gubuChange, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        fetchSet();
        dataUpdate(defaultData);
    }, [fetchSet, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            clieName: data.clie_name || '',
            clieEnam: data.clie_enam || '',
            presName: data.pres_name || '',
            clieSano: data.clie_sano || '',
            clieLoca: data.clie_loca || '',
            uptaName: data.upta_name || '',
            jongMoks: data.jong_moks || '',
            clieGubu: data.clie_gubu[0] || '',
            teleNumb: data.tele_numb || '',
            faxsNumb: data.faxs_numb || '',
            bankName: data.bank_name || '',
            holdName: data.hold_name || '',
            accoNumb: data.acco_numb || '',
            zipsCode: data.zips_code || '',
            addrKnam: data.addr_knam || '',
            addrEnam: data.addr_enam || '',
            damdName: data.damd_name || '',
            deptName: data.dept_name || '',
            damdTeln: data.damd_teln || '',
            damdFaxn: data.damd_faxn || '',
            mailIdno: data.mail_idno || '',
            clieMemo: data.clie_memo || '',
            workCode: data.work_code || '',
            posiName: data.posi_name || '',
            damdCell: data.damd_cell || '',
            homePage: data.home_page || '',
            clieSert: data.clie_sert || '',
            evalDate: data.eval_date ? fwUtil.date.convTime.fmDate(data.eval_date) : null,
            evalScor: data.eval_scor || '',
            evalTerm: data.eval_term || '',
            evalMemo: data.eval_memo || '',
            taxbName: data.taxb_name || '',
            taxbTeln: data.taxb_teln || '',
            taxbMail: data.taxb_mail || '', 
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return (
        <>
        <PopupForm
            title={'거래처 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? '100%' : isMedium ? 600 : 1200 }
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 4} cssClass='clie-form'>
                <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form comp'>
                    <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 }> 
                        {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '업체번호[자동]', disabled: true })}
                    </GroupItem>
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '업체명[한]', required: true })}
                    {FormText({ value: data.clie_enam, onValueChange: updateField('clie_enam'), label: '업체명[영]' })}
                    {FormRegx({ value: data.clie_sano, onValueChange: updateField('clie_sano'), label: '사업자번호', required: true, placeholder: "- 포함 입력",rule: /^\d{3}-\d{2}-\d{5}$/ })}
                    {FormText({ value: data.pres_name, onValueChange: updateField('pres_name'), label: '대표자명' })}
                    {FormSelc({ value: data.clie_gubu, onValueChange: updateField('clie_gubu'), label: '업체구분', required: true, items: gubuList })}
                    {FormSelc({ value: data.clie_loca, onValueChange: updateField('clie_loca'), label: '국내/국외', items: ['국내', '국외'] })}
                    {FormText({ value: data.upta_name, onValueChange: updateField('upta_name'), label: '업태' })}
                    {FormText({ value: data.jong_moks, onValueChange: updateField('jong_moks'), label: '종목' })}
                    {FormText({ value: data.tele_numb, onValueChange: updateField('tele_numb'), label: '전화번호', placeholder: "-포함 입력" })}
                    {FormText({ value: data.faxs_numb, onValueChange: updateField('faxs_numb'), label: '팩스번호', placeholder: "-포함 입력" })}
                    {FormAuto({ value: data.bank_name, onValueChange: updateField('bank_name'), label: '거래은행', dataSource: bankList })}
                    {FormText({ value: data.hold_name, onValueChange: updateField('hold_name'), label: '예금주' })}
                    <GroupItem colSpan={isXSmall ? 1 : 2 }>
                        {FormText({ value: data.acco_numb, onValueChange: updateField('acco_numb'), label: '계좌번호' })}
                    </GroupItem>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormGrid({ value: data.zips_code, onValueChange: updateField('zips_code'), onClick: () => setAddrPopupVisible(true), label: '우편번호' })}
                    </GroupItem>
                    {FormText({ value: data.addr_knam, onValueChange: updateField('addr_knam'), label: '주소[한]' })}
                    {FormText({ value: data.addr_enam, onValueChange: updateField('addr_enam'), label: '주소[영]' })}
                </GroupItem>
                <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form-wrapper'>
                    <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form damd'>
                        {FormText({ value: data.damd_name, onValueChange: updateField('damd_name'), label: '담당자' })}
                        {FormText({ value: data.dept_name, onValueChange: updateField('dept_name'), label: '부서명' })}
                        {FormText({ value: data.posi_name, onValueChange: updateField('posi_name'), label: '직위명' })}
                        {FormText({ value: data.damd_teln, onValueChange: updateField('damd_teln'), label: '담당전화', placeholder: "-포함 입력" })}
                        {FormText({ value: data.damd_faxn, onValueChange: updateField('damd_faxn'), label: '담당팩스', placeholder: "-포함 입력" })}
                        {FormText({ value: data.damd_cell, onValueChange: updateField('damd_cell'), label: '담당자휴대폰', mask: '000-0000-0000' })}
                        {FormRegx({ value: data.mail_idno, onValueChange: updateField('mail_idno'), label: '담당이메일', rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                        {FormText({ value: data.taxb_name, onValueChange: updateField('taxb_name'), label: '세금계산 담당자' })}
                        {FormText({ value: data.taxb_teln, onValueChange: updateField('taxb_teln'), label: '세금계산 담당전화', placeholder: "-포함 입력" })}
                        {FormText({ value: data.taxb_mail, onValueChange: updateField('taxb_mail'), label: '세금계산 담당메일', rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                    </GroupItem>
                    <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form eval'>
                        {FormText({ value: data.eval_scor, onValueChange: updateField('eval_scor'), label: '평가점수', maxLength: 10 })}
                        {FormDate({ value: data.eval_date, onValueChange: updateField('eval_date'), label: '평가일' })}
                        <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 }>
                            {FormText({ value: data.eval_term, onValueChange: updateField('eval_term'), label: '평가주기', maxLength: 10 })}
                        </GroupItem>
                        <GroupItem colSpan={isXSmall ? 1 : 2 }>
                            {FormMemo({ value: data.eval_memo, onValueChange: updateField('eval_memo'), label: '평가비고' })}
                        </GroupItem>
                    </GroupItem>                    
                </GroupItem>
                <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 4 } colSpan={isXSmall ? 1 : isSmall || isMedium ? 2 : 4 } cssClass='remk-form etcs'>
                    {FormText({ value: data.work_code, onValueChange: updateField('work_code'), label: '생산지시코드', maxLength: 10 })}
                    {FormText({ value: data.clie_sert, onValueChange: updateField('clie_sert'), label: '인증서보유현황' })}
                    <GroupItem colSpan={isXSmall ? 1 : 2 } >
                        {FormText({ value: data.home_page, onValueChange: updateField('home_page'), label: '홈페이지' })}
                    </GroupItem>
                    <GroupItem colSpan={isXSmall ? 1 : isSmall || isMedium ? 2 : 4 }>
                        {FormMemo({ value: data.clie_memo, onValueChange: updateField('clie_memo'), label: '비고' })}                        
                    </GroupItem>
                </GroupItem>
            </GroupItem>
        </PopupForm>
        <PopupAddr
            visible={addrPopupVisible}
            hiding={addrPopupHide}
            confirm={addrPopupConfirm}
        />        
        </>
    );
}