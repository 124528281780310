// LogsInfoStat - 로그 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { ViewGridCard } from "./view-grid";
import { ViewMainChar } from "./view-main-char";
import { ViewSubsChar } from "./view-subs-char";
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import "./view.scss";

export default function LogsInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle,
    } = props;

    // api
    const searchInfoApi = `${acce}/${gubu}/${cata}/${catb}/view/info/search`;  // 조회 api
    const searchUserApi = `${acce}/${gubu}/${cata}/${catb}/view/user/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [dashData, setDashData] = useState([]);
    const dashDataUpdate = useCallback((e) => setDashData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const [userIdno, setUserIdno] = useState("");
    const userIdnoChange = useCallback((e) => { setUserIdno(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        setPageLoading(true);
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, userIdno: userIdno};
        await fwUtil.apis.search(searchInfoApi, sendData, dashDataUpdate, dashRef, null);
        setPageLoading(false);
    }, [dateValue, duraYesn, userIdno, dashDataUpdate, dashRef, searchInfoApi]);
 
    const [userList, setUserList] = useState([]);    
    const userListChange = useCallback((e) => { setUserList(e) }, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fwUtil.apis.search(searchUserApi);
                let userNameList = [];
                if (data && data.length > 0) {
                    userNameList = data.map(item => item);
                }

                if (userNameList && userNameList.length > 0) {
                    console.log('userNameList:',userNameList);
                    userListChange(userNameList);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [userListChange, searchUserApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        {
            type: 'selc', locate: 'before', caption: '사용자명', value: userIdno, valueChange: userIdnoChange, search: handleSearch, 
            items: userList ,valueExpr: "user_idno",displayExpr: "user_name"},
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [userIdno, userIdnoChange, userList, handleSearch]);

    return (
        <div className="fw-dash-page fw-flex-box col view">
            <FwAppLoader loading={loading} />
            <CommDash
                title={mainTitle}
                dashRef={dashRef}
                duraYesn={duraYesn}
                dateValue={dateValue}
                duraYesnChange={duraYesnChange}
                dateValueChange={dateValueChange}
                exp={{ fileName: "로그통계", disabled: authList.auth_dnlo === 0 }}
                toolbar={{ items: toolbarItems }}
            >
                <div className="fw-dash-row-wrapper">
                    <ViewMainChar loading={pageLoading} data={dashData} />
                    <ViewSubsChar loading={pageLoading} data={dashData} />
                </div>
                <ViewGridCard loading={pageLoading} data={dashData} />
            </CommDash>      
        </div>
    );
};