import React, { useCallback, useEffect, useRef, useState } from "react";
import { fwUtil } from "../../../../script/util";
import { useAuth } from "../../../../../contexts/auth";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { DashMainItemCard } from "./Dash-main-item";
import { DashProdItemCard } from "./Dash-prod-item";
import { DashQcisItemCard } from "./Dash-qcis-item";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import "./dash.scss";

export default function KpisInfoStat(props) {
    const { acce, gubu, cata, catb, code,title, } = props;

    // api
    const searchPlanApi = `${acce}/${gubu}/${cata}/${catb}/plan/search`;  // KPI 조회 api
    const insertPlanApi = `${acce}/${gubu}/${cata}/${catb}/plan/insert`;  // KPI 조회 api
    const updatePlanApi = `${acce}/${gubu}/${cata}/${catb}/plan/update`;  // KPI 조회 api
    const deletePlanApi = `${acce}/${gubu}/${cata}/${catb}/plan/delete`;  // KPI 조회 api
    const searchProdApi = `${acce}/${gubu}/${cata}/${catb}/prod/search`;  // 산출근거 조회 api
    const searchQcisApi = `${acce}/${gubu}/${cata}/${catb}/qci/search`;   // 산출근거 조회 api

    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [planData, setPlanData] = useState([]);
    const planDataUpdate = useCallback((e) => setPlanData(e), []);
    const [selecGrid, setSelecGrid] = useState("");
    const selecGridUpdate = useCallback((e) => setSelecGrid(e), []);
    const [prodData, setProdData] = useState([]);
    const prodDataUpdate = useCallback((e) => setProdData(e), []);
    const [qcisData, setQciData] = useState([]);
    const qcisDataUpdate = useCallback((e) => setQciData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    // 조회
    const mainHandleSearch = useCallback(async () => {
        setPageLoading(true);
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn };

        const prodResult = await fwUtil.apis.search(searchProdApi, sendData, prodDataUpdate);
        //console.log('prodResult === ', prodResult);
        let prodDateDiff = 0;
        let prodDateDhip = 0;
        for (let i = 0; i < prodResult.length; i++) {
            const row = prodResult[i];
            prodDateDiff = prodDateDiff + +row.date_diff;
            prodDateDhip = prodDateDhip + +row.date_ship;
        }
        const dateDiff = (prodDateDhip / prodDateDiff).toFixed(1);
        //console.log('dateDiff === ', dateDiff);

        const qcisResult = await fwUtil.apis.search(searchQcisApi, sendData, qcisDataUpdate);
        //console.log('qcisResult === ', qcisResult);
        let qcisWorkQtys = 0; //작업수량
        let qcisInfeQtys = 0; //불량수량량
        for (let i = 0; i < qcisResult.length; i++) {
            const row = qcisResult[i];
            qcisWorkQtys = qcisWorkQtys + +row.work_qtys;
            qcisInfeQtys = qcisInfeQtys + +row.infe_qtys;
        }
        
        const planResult = await fwUtil.apis.search(searchPlanApi, sendData);
        const tempMains = []
        for (let i = 0; i < planResult.length; i++) {
            const row = planResult[i];
            const kpisBefo = row.kpis_befo
            let kpisAfte = 0; 
            let kpisValu = 0; 
            if (row.kpis_gubu === "P") {
                kpisAfte = dateDiff;
                kpisValu = (((kpisBefo - kpisAfte) / kpisBefo) * 100).toFixed(1);
            } else if (row.kpis_gubu === "Q") {
                kpisAfte = ((qcisInfeQtys / qcisWorkQtys) * 100).toFixed(1);
                kpisValu = (kpisBefo - kpisAfte).toFixed(1);
            } 
            tempMains.push({
                kpis_gubu: row.kpis_gubu, kpis_name: row.kpis_name, kpis_unit: row.kpis_unit, kpis_befo: kpisBefo, 
                kpis_afte: kpisAfte, kpis_valu: kpisValu });
        }
        planDataUpdate(tempMains);
        setPageLoading(false);
    }, [dateValue, duraYesn, searchPlanApi, planDataUpdate, searchQcisApi, qcisDataUpdate, searchProdApi, prodDataUpdate]);

    const sandPlanEdit = useCallback(async (e, edit) => {
        const rowData = e.row.data;
        const kpisGubu = rowData.kpis_gubu || '';
        if (kpisGubu !== '') {
            const postData = {
                kpisGubu: kpisGubu,
                kpisName: rowData.kpis_name || '',
                kpisUnit: rowData.kpis_unit || '',
                kpisBefo: rowData.kpis_befo || '',
            }
            const axiosInstance = fwUtil.axiosInstanceCreate();
            if (edit === "U") {
                const response = await axiosInstance.post(`${updatePlanApi}`, postData);
            } else if (edit === "D") {
                const response = await axiosInstance.post(`${deletePlanApi}`, postData);
            } else {
                const response = await axiosInstance.post(`${insertPlanApi}`, postData);
            }
        }
    }, [insertPlanApi, updatePlanApi, deletePlanApi,]);

    const onRowInsertClick = useCallback(async (e) => {
        await sandPlanEdit(e, "I");
        mainHandleSearch();
    }, [mainHandleSearch, sandPlanEdit]);

    const onRowUpdateClick = useCallback(async (e) => {
        await sandPlanEdit(e, "U");
        mainHandleSearch();
    }, [mainHandleSearch, sandPlanEdit]);

    const onRowDeleteClick = useCallback(async (e) => {
        await sandPlanEdit(e, "D");
        mainHandleSearch();
    }, [mainHandleSearch, sandPlanEdit]);

    useEffect(() => {
        // 접근 권한 조회 및 권한 상태값 설정
        authCheck(setLoading, setAuthList, gubu, code);
        mainHandleSearch();
    }, [
        authCheck, setLoading, setAuthList, gubu, code,
        mainHandleSearch
    ]);

    const renderContent = () => {
        if (selecGrid === "P") {
            return (
                <DashProdItemCard loading={pageLoading} data={prodData} />
            );
        } else {
            return (
                <DashQcisItemCard loading={pageLoading} data={qcisData} />
            );
        }
    };

    return (
        <div className="fw-dash-page fw-flex-box col kpis">
            <FwAppLoader loading={loading} />
            <CommDash
                title={title}
                dashRef={dashRef}                
                duraYesn={duraYesn}                
                dateValue={dateValue}
                duraYesnChange={duraYesnChange}                
                dateValueChange={dateValueChange}
                search={mainHandleSearch}
                exp={{ fileName: "KPI현황", disabled: authList.auth_dnlo === 0 }}
                toolbar
            >
                <DashMainItemCard loading={pageLoading} mainData={planData} authList={authList} 
                    gridSelect={selecGridUpdate} onRowInsertClick={onRowInsertClick} onRowUpdateClick={onRowUpdateClick} onRowDeleteClick={onRowDeleteClick}/>
                {renderContent()}
            </CommDash>
        </div>
    )
}