import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";
import { Summary } from "devextreme-react/cjs/data-grid";
import { GroupSumItem } from "../../../../script/components/data-grid/data-grid-body/grid-body-item";
import { fwUtil } from "../../../../script/util";

const itemGroupRender = (data) => {

    if (!data && (data && data.length < 1)) {
        return;
    }

    if (!data.summaryItems) {
        return;
    }
    const ordeColumn = data.summaryItems[0];

    if (!ordeColumn) {
        return;
    }

    return (
        <div className='fw-summary-group'>
            <div className='fw-group-title'>
                {data.displayValue}
            </div>
            <div className='fw-group-detail'>
                총 {ordeColumn.columnCaption} : <b>{ordeColumn.value}</b> 건
            </div>
        </div>
    )
}

export function ViewGridCard(props) {
    const { loading, data } = props;
    return (
        <DashCard
            title={"상세 현황"}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={["user_idno", "logs_date", "logs_gubu", "scre_name"]}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "user_name", caption: "사용자계정", groupIndex: 0, groupRender: itemGroupRender })}
                {CommCol({ dataField: "logs_date", caption: "사용일자" })}
                {CommCol({ dataField: "scre_name", caption: "접속화면" })}
                {CommCol({ dataField: "logs_gubu", caption: "기능구분" })}
                {CommCol({ dataField: "grou_coun", caption: "사용횟수" })}
                <Summary>
                    {GroupSumItem({ dataField: "grou_coun" })}
                </Summary>
            </ScrollGrid>
        </DashCard>
    )
}